<template>
    <div id="termekek" class="products pb-5 pt-2 position-relative">
        <div class="container-xl">                    
            <div class="row">                
                <div class="col-md-12 d-flex flex-column text-center pt-md-5">
                    <h2 class="font-header text-dark fw-bold text-center my-7">
                        Promócióban részt vevő termékek
                    </h2>
                    <div class="products-container__inner mx-auto">       
                        <p>Magyarországon valamennyi SPAR áruházban vagy a <a href="https://www.spar.hu/onlineshop/" target="_blank">SPAR Online shopban</a> vásárolt a Játékban részt vevő Jana termékek. A választék áruházanként eltérő lehet.<br><br><b>Figyelem! A SPAR expressz és DESPAR üzletek nem vesznek részt a Játékban!</b></p>
                    </div>
                </div>
                <div class="col-12 col-lg-10 offset-lg-1">
                    <VueSlickCarousel v-bind="settings">
                        <div><img src="imgs/products/product-1.png" class="img-fluid" alt="jana"/></div>
                        <div><img src="imgs/products/product-2.png" class="img-fluid" alt="jana"/></div>
                        <div><img src="imgs/products/product-3.png" class="img-fluid" alt="jana"/></div>
                        <div><img src="imgs/products/product-4.png" class="img-fluid" alt="jana"/></div>
                        <div><img src="imgs/products/product-5.png" class="img-fluid" alt="jana"/></div>
                        <div><img src="imgs/products/product-6.png" class="img-fluid" alt="jana"/></div>
                        <div><img src="imgs/products/product-7.png" class="img-fluid" alt="jana"/></div>
                        <div><img src="imgs/products/product-8.png" class="img-fluid" alt="jana"/></div>
                        <div><img src="imgs/products/product-9.png" class="img-fluid" alt="jana"/></div>
                        <div><img src="imgs/products/product-10.png" class="img-fluid" alt="jana"/></div>
                        <div><img src="imgs/products/product-11.png" class="img-fluid" alt="jana"/></div>
                        <div><img src="imgs/products/product-12.png" class="img-fluid" alt="jana"/></div>
                        <div><img src="imgs/products/product-13.png" class="img-fluid" alt="jana"/></div>
                    </VueSlickCarousel>
                </div>                 
                <div class="col-12 text-center mt-4 mt-md-8">
                     <a class="btn btn-primary me-lg-3 mb-2 mb-lg-0" @click="setGtm('Megveszem')" href="https://www.spar.hu/onlineshop/" target="_blank">
                       Megveszem
                    </a>
                    <a class="btn btn-secondary" @click="setGtm('Termékek listája')" :href="$api_conf.apiDomain+'/pdf/resztvevo_termekek.pdf?'" target="_blank">
                        Részt vevő termékek listája
                    </a>
                </div>  
                <div class="col-12">
                    <div class="d-flex flex-column">
                        <p class="text-center text-white mt-7" style="font-size:12px;">A kép csak illusztráció!</p>
                    </div>
                </div>  
            </div>         
        </div>
    </div>
</template>

<script>

import VueSlickCarousel from 'vue-slick-carousel'

export default {
    components: { VueSlickCarousel },
    data() {
      return {
        settings: {
            arrows: true,
            dots: false,
            slidesToShow: 6,
            slidesToScroll: 1,
            initialSlide: 0,
            responsive: [{
                breakpoint: 1200,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                }
            },
            {
                breakpoint: 530,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 380,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }]
        },
      }
    },
    methods: {   
        setGtm(btnName){
            this.GTtrackGA4({
                'event' : 'event',
                'category': 'jatekban_reszt_vevo_termekek',
                'action': 'gomb_kattintas',
                'label': btnName,                
                'clicked_text':btnName,
                'action_type': 'gomb_kattintas'
            })
        }
    }
}
</script>
<template>
    <div>
        <div class="hero__outer">
            <div class="hero" id="hero">        
                <div class="hero-container">
                    <!-- CONTENT -->
                    <div class="container">
                        <div class="row py-4">
                            <!-- Infok -->
                            <div class="col-12 col-lg-6 position-relative d-flex justify-content-center align-items-lg-start align-items-center flex-column">
                                <h1 class="text-black">Töltődj fel<br>a Janával!</h1>
                                <div class="d-none d-lg-block">
                                    <p class="hero__time-text text-black">A promóció időtartama:</p>
                                    <p class="hero__date-wrap fw-bolder">2023. szeptember 7 - október 4.</p>
                                    <div class="text-start text-dark mt-4">
                                        <button v-if="$store.state.promoStatus != 'before'"
                                            class="btn btn-primary mb-3 order-md-2 me-md-2 md-btn"
                                            v-scroll-to="{el:!$store.state.user ? '#regisztracio' : '#palyazat',  offset: -80}"
                                            @click="setGtm('Játszom')"
                                        >
                                            Játszom!
                                        </button>
                                        <a class="btn btn-secondary me-0 ms-lg-2 order-md-1 mb-3 md-btn" :href="$store.state.file_url" target="_blank" @click="setGtm('Játékszabályzat')">
                                            Játékszabályzat
                                        </a>   
                                    </div>   
                                </div>
                            </div>
                            <div class="col-12 col-lg-6 d-flex justify-content-end align-items-lg-end align-items-center flex-column ">
                                <img src="@/assets/imgs/hero-product.png" alt="Napi nyeremény" class="img-fluid d-none d-md-block"/> 
                                <img src="@/assets/imgs/hero-product-mobile.png" alt="Napi nyeremény" class="img-fluid d-block d-md-none"/> 
                                <div class="d-block d-lg-none text-center">
                                    <p class="hero__time-text text-black">A promóció időtartama:</p>
                                    <p class="hero__date-wrap fw-bolder">2023. szeptember 7 - október 4.</p>
                                    <div class="text-center text-dark mt-4">
                                        <button v-if="$store.state.promoStatus != 'before'"
                                            class="btn btn-primary mb-3 order-md-2 me-md-2 md-btn"
                                            v-scroll-to="{el:!$store.state.user ? '#regisztracio' : '#palyazat',  offset: -80}"
                                            @click="setGtm('Játszom')"
                                        >
                                            Játszom!
                                        </button>
                                        <a class="btn btn-secondary me-0 ms-lg-2 order-md-1 mb-3 md-btn" :href="$store.state.file_url" target="_blank" @click="setGtm('Játékszabályzat')">
                                            Játékszabályzat
                                        </a>   
                                    </div>   
                                </div>
                            </div>                        
                        </div>
                    </div>
                </div>
                <div class="hero__info-text text-center text-black pt-3 pb-lg-5">
                    <div class="container-xl">
                        <div class="row">
                            <div class="col-sm-12 col-md-10 col-lg-8 offset-md-1 offset-lg-2 mb-3">
                                A vásárlást igazoló eredeti bizonylatot sértetlen, ép állapotban <span v-html="$store.state.keep_recipe_date"></span>-ig, illetve nyertesség esetén a nyeremény átadásáig meg kell őrizni! A képek illusztrációk!
                            </div>
                        </div>
                    </div>            
                </div>    
            </div>
        </div>
    </div>
</template>

<script>

//import Ribbon from '@/components/Ribbon.vue'

export default {
    components:{
        //Ribbon
    },
    methods: {      
        setGtm(btnName){
            this.GTtrackGA4({
                'event' : 'event',
                'category': 'jatekleiras',
                'action': 'gomb_kattintas',
                'label': btnName,                
                'clicked_text':btnName,
                'action_type': 'gomb_kattintas'
            })
        }
    }
}
</script>